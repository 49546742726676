
.color-picker {
  position: relative;
}
.color-picker_pallete {
  position: absolute;
  bottom: -250px;
}
.square_color__empty {
  background: #dedede;
  background-image: repeating-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.15) 6px,
    transparent 0,
    transparent 15px
  );
}
